import React from "react";
import bieber from "../assets/bieber.webp";
const Bieber = () => {
  return (
    <div className="px-[2rem] xl:px-[9.125rem] my-[6rem]">
      <div className="bg-[#6D894E] h-[104px] justify-center flex flex-row items-center">
        <h2 className="uppercase text-[white] text-center  p-auto font-[700] text-[24px] md:text-[41px] leading-[35px] md:leading-[55px]">
          Barrister Peter Bieba - Company secretary
        </h2>
      </div>
      <div
        className="flex my-[4rem] gap-[5rem] flex-col md:flex-row w-[100%] max-w-[100%] items-center
       justify-between"
      >
        <img
          src={bieber}
          className="w-[100%] max-w-[100%] md:max-w-[50%] "
          alt="bieber"
        />
        <div className="w-[100%] flex flex-col justify-between items-center max-w-[100%] md:max-w-[50%]">
          <div className="font-[500] items-start text-[18px] md:text-[20px] leading-[50px] text-black font-[Manrope]">
            <p>
              {" "}
              As the Company Secretary, Barrister Peter Bieba brings a wealth of expertise in legal
              practice and property consultancy, with a strong background in law
              and public administration. 
            </p>

            <p className="py-[1rem]">
            Barrister Peter Bieba excels in strategic planning,
              legal advisory, and professional service delivery, ensuring high
              standards of practice and client satisfaction. Their commitment to
              improving the human experience through quality professional
              services drives continuous improvement and success in their role
              at Bebia Bebia and Associates.
            </p>

           
          </div>
          <div className="border items-end h-[6px]  bg-[#6D894E] border-b-[#6D894E] w-[100%] my-[2rem]"></div>
        </div>
      </div>
    </div>
  );
};

export default Bieber;
