import React from "react";
import calebbig from "../assets/calebbig.webp";
const Caleb = () => {
  return (
    <div className="px-[2rem] xl:px-[9.125rem] my-[6rem]">
      <div className="bg-[#6D894E] h-[104px] justify-center flex flex-row items-center">
        <h2 className="uppercase text-[white] text-center  p-auto font-[700] text-[24px] md:text-[41px] leading-[35px] md:leading-[55px]">
          Caleb Akoh - TECHNICAL MANAGER
        </h2>
      </div>
      <div
        className="flex my-[4rem] gap-[5rem] flex-col md:flex-row w-[100%] max-w-[100%] items-center
       justify-between"
      >
        <img
          src={calebbig}
          className="w-[100%] max-w-[100%] md:max-w-[50%] "
          alt="calebbig"
        />
        <div className="w-[100%] flex flex-col justify-between items-center max-w-[100%] md:max-w-[50%]">
          <div className="font-[500] items-start text-[18px] md:text-[20px] leading-[50px] text-black font-[Manrope]">
            <p>
              {" "}
              The Head of Technical Services at Alvany Energy, brings a wealth
              of experience and technical expertise garnered over a diverse
              career in electrical engineering and technical supervision. With a
              solid educational foundation and a proven track record in both
              maintenance and supervisory roles, Caleb excels in optimizing
              systems and leading technical initiatives.{" "}
            </p>

            <p className="py-[1rem]">
              His comprehensive knowledge in servicing and installing electrical
              and electronics equipment, combined with his leadership
              experience, ensures that Alvany Energy continues to operate at the
              highest standards of efficiency and innovation.
            </p>
          </div>
          <div className="border items-end h-[6px]  bg-[#6D894E] border-b-[#6D894E] w-[100%] my-[2rem]"></div>
        </div>
      </div>
    </div>
  );
};

export default Caleb;
