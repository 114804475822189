import React, { useEffect } from "react";
import biomass from "../assets/biomass.webp";
import greenhouse from "../assets/greenhouse.webp";
import solar from "../assets/solarbllue.webp";
import hybrid from "../assets/hybrid.webp";
import research from "../assets/research.webp";
import consultancy from "../assets/consultancy.webp";
import useIntersectionObserver from "./useIntersectionObserver";
import { NavLink } from "react-router-dom";

const Whatwedo = () => {
  const observe = useIntersectionObserver(
    (element) => {
      element.classList.add("animate-slideUp");
    },
    { threshold: 0.1 }
  );

  useEffect(() => {
    const cards = document.querySelectorAll(".section");
    cards.forEach((card) => observe(card));
  }, [observe]);

  return (
    <div className="px-[2rem] h-fit xl:px-[9.125rem] my-[4rem]">
      <h2 className="font-[Manrope] py-[2rem] text-center leading-[54.47px] text-[#7F9764] text-[40px] font-[800]">
        WHAT WE DO
      </h2>
      <div>
        <div className="border-0 section lg:border my-[3rem] h-fit flex flex-col lg:flex-row gap-[5rem] 2xl:gap-[10rem] items-center justify-between drop-shadow-md rounded-[16px] ">
          <div className="pl-0 md:pl-[2rem] ">
            <h2 className="font-[Manrope] text-left leading-[35px] md:leading-[54.47px] text-[#7F9764] text-[25px] md:text-[40px] font-[800]">
              Commercial and Biomass Energy Production
            </h2>
            <p className="font-[Manrope] text-left leading-[30px] text-[#000000] text-[18px] font-[500]">
              As fossil fuel prices continue to rise, we focus on producing
              clean energy from agricultural products such as plantains, wheat,
              rice, corn, and soybeans. This initiative not only mitigates
              household expenditures but also fosters sustainable energy
              practices, reducing reliance on traditional petrol products.
              Biomass energy is a cornerstone of our approach, transforming
              agricultural waste into a valuable energy resource. We utilize
              advanced technologies to convert organic materials into biofuels
              and biogas, providing a sustainable and efficient energy solution.
            </p>
            <NavLink to="/commercial-and-biomass-energy-production" >
              <button className="w-fit p-[1rem] bg-[#7F9764] text-white rounded-md my-[1rem]">
                Read Portfolio
              </button>
            </NavLink>
          </div>
          <img src={biomass} className="h-fit" alt="biomass-icon" />
        </div>

        <div className="border-0 section my-[3rem] lg:border mt-[3rem] md:mt-0 h-fit lg:h-[22rem] flex flex-col lg:flex-row gap-[5rem] 2xl:gap-[10rem] items-center justify-between drop-shadow-md rounded-[16px] ">
          <img className="" src={greenhouse} alt="greenhouse-icon" />

          <div className="pr-0 order-first lg:order-last text-left lg:text-right lg:pr-[2rem] ">
            <h2 className="font-[Manrope]  leading-[35px] md:leading-[54.47px] text-[#7F9764] text-[25px] md:text-[40px] font-[800]">
              Greenhouse Farming
            </h2>
            <p className="font-[Manrope] leading-[30px] text-[#000000] text-[18px] font-[500]">
              To address the critical shortage of food, fruits, and vegetables,
              especially among low-income households, we operate
              state-of-the-art greenhouse farming facilities. These facilities
              ensure a continuous supply of fresh produce. With the increasing
              demand for our products, we are expanding our production capacity
              to better serve our communities.
            </p>
            <NavLink to="/greenhouse-farming" >
              <button className="w-fit p-[1rem] bg-[#7F9764] text-white rounded-md mt-[2rem]">
                Read Portfolio
              </button>
            </NavLink>
          </div>
        </div>

        <div className="border-0 section lg:border my-[3rem] md:mb-[3rem] h-fit lg:h-[22rem] flex flex-col lg:flex-row gap-[5rem] 2xl:gap-[10rem] items-center justify-between drop-shadow-md rounded-[16px] ">
          <div className="pl-0 md:pl-[2rem] ">
            <h2 className="font-[Manrope]  leading-[35px] md:leading-[54.47px] text-[#7F9764] text-[25px] md:text-[40px] font-[800]">
              Commercial and Industrial Solar Panel Installation
            </h2>
            <p className="font-[Manrope] text-left leading-[30px] text-[#000000] text-[18px] font-[500]">
              We provide cost-effective solar panel installations for both
              commercial and residential properties. Our goal is to reduce
              electricity expenses and provide reliable power solutions to
              households that lack adequate access to electricity.
            </p>
            <NavLink to="/commercial-and-industrial-solar-panel-installation" >
              <button className="w-fit p-[1rem] bg-[#7F9764] text-white rounded-md mt-[2rem]">
                Read Portfolio
              </button>
            </NavLink>
          </div>
          <img src={solar} alt="solar-icon" />
        </div>

        <div className="border-0 section lg:border my-[3rem] h-fit lg:h-[22rem] flex flex-col lg:flex-row gap-[5rem] xl:gap-[10rem] items-center justify-between drop-shadow-md rounded-[16px] ">
          <img src={research} alt="research-icon" />

          <div className="pr-0 order-first lg:order-last text-left lg:text-right lg:pr-[2rem] ">
            <h2 className="font-[Manrope]  leading-[35px] md:leading-[54.47px] text-[#7F9764] text-[25px] md:text-[40px] font-[800]">
              Research and Analytics
            </h2>
            <p className="font-[Manrope] leading-[30px] text-[#000000] text-[18px] font-[500]">
              We conduct comprehensive research and offer analytical services to
              drive innovation and efficiency in the renewable energy and
              agricultural sectors. Our insights help shape sustainable
              practices and optimize resource utilization.
            </p>
            <NavLink to="/research-and-analytics" >
              <button className="w-fit p-[1rem] bg-[#7F9764] text-white rounded-md mt-[2rem]">
                Read Portfolio
              </button>
            </NavLink>
          </div>
        </div>

        <div className="border-0 section lg:border mb-[5rem] md:mb-0 h-fit lg:h-[22rem] flex flex-col lg:flex-row gap-[5rem] 2xl:gap-[10rem] items-center justify-between drop-shadow-md rounded-[16px] ">
          <div className="pl-0 md:pl-[2rem]">
            <h2 className="font-[Manrope] text-left leading-[35px] md:leading-[54.47px] text-[#7F9764] text-[25px] md:text-[40px] font-[800]">
              Hybrid Bio-Energy Systems for SMEs
            </h2>
            <p className="font-[Manrope] text-left leading-[30px] text-[#000000] text-[18px] font-[500]">
              Our hybrid bio-energy systems are tailored to meet the needs of
              small and medium-sized enterprises (SMEs) and households in
              underserved areas. These systems provide a sustainable and
              dependable energy source, enhancing economic activities and
              quality of life.
            </p>
            <NavLink to="/hybrid-bio-energy-systems-for-smes" >
              <button className="w-fit p-[1rem] bg-[#7F9764] text-white rounded-md mt-[2rem]">
                Read Portfolio
              </button>
            </NavLink>
          </div>
          <img className="" src={hybrid} alt="hybrid-icon" />
        </div>

        <div className="border-0 section lg:border my-[3rem] h-fit lg:h-[22rem] flex flex-col lg:flex-row gap-[5rem] xl:gap-[10rem] items-center justify-between drop-shadow-md rounded-[16px] ">
          <img src={consultancy} alt="consultancy-icon" />

          <div className="pr-0 text-left order-first lg:order-last lg:text-right lg:pr-[2rem] ">
            <h2 className="font-[Manrope] text-left md:text-right leading-[35px] md:leading-[54.47px] text-[#7F9764] text-[25px] md:text-[40px] font-[800]">
              Consultancy Services
            </h2>
            <p className="font-[Manrope] leading-[30px] text-[#000000] text-[18px] font-[500]">
              Our consultancy services provide expert guidance on the adoption
              and implementation of sustainable energy solutions. We assist
              clients in navigating the complexities of renewable energy
              projects and maximizing their benefits.
            </p>
            <NavLink to="/consultancy-services" >
              <button className="w-fit p-[1rem] bg-[#7F9764] text-white rounded-md mt-[2rem]">
                Read Portfolio
              </button>
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Whatwedo;
