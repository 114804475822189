import { useEffect, useRef } from "react";

const useIntersectionObserver = (callback, options) => {
  const observerRef = useRef(null);

  useEffect(() => {
    observerRef.current = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          callback(entry.target);
          // Unobserve the element after it is animated to prevent twitching
          observerRef.current.unobserve(entry.target);
        }
      });
    }, options);

    return () => {
      observerRef.current.disconnect();
    };
  }, [callback, options]);

  const observe = (element) => {
    if (observerRef.current && element) {
      observerRef.current.observe(element);
    }
  };

  return observe;
};

export default useIntersectionObserver;
