import React from 'react';
import Bernice from '../components/Bernice';

const BernicePage = () => {
  return (
    <div>
      <Bernice/>
    </div>
  );
}

export default BernicePage;
