import React from "react";
import ContactHero from "./../components/ContactHero";
import ContactComponent from "../components/ContactComponent";
import SEO from "../components/SEO";

const Contact = () => {
  return (
    <div>
        <SEO
        title="Alvany Energy – We are a call away"
        description="Reach out to us and join our mission to power a greener, healthier
            planet"
        image="/public/logo.svg"
        keywords="energy, sustainability, clean energy, green energy, renewable energy"
        url="https://www.alvanyenergy.org/contact"
      />{" "}
     
      <ContactHero />
      <ContactComponent />
    </div>
  );
};

export default Contact;
