import React from 'react';
import Bella from '../components/Bella';

const BellaPage = () => {
  return (
    <div>
      <Bella/>
    </div>
  );
}

export default BellaPage;
