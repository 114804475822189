import { useEffect } from "react";
import Glide from "@glidejs/glide";
import "../css/glide.core.css";
import "../css/glide.theme.css";
import solution from "../assets/solutions.webp";
import approach from "../assets/approach.webp";
import community from "../assets/community.webp";
import stewardship from "../assets/stewardship.webp";
import economy from "../assets/economy.webp";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const Carousel = () => {
  useEffect(() => {
    new Glide(".whychooseus", {
      perView: 3,
      type: "carousel",
      breakpoints: {
        420: {
          perView: 1,
        },
        768: {
          perView: 1,
        },
        850: {
          perView: 1,
        },
      },
      gap: 20,
    }).mount();
  }, []);

  return (
    <div className="whychooseus relative px-[2rem] lg:px-[3.125rem] mx-[1rem] my-[5rem]">
      <div className="glide__track relative" data-glide-el="track">
        <div className="glide__slides relative mx-auto px-auto flex flex-row lg:justify-center items-center">
          <div className="glide__slide">
            <div className="border-[1px] border-[white] h-fit md:h-[33.188rem] flex flex-col justify-center items-center w-fit drop-shadow-lg rounded-[16px]">
              <img src={solution} alt="solution-image" />
              <h1 className="text-[26px] pt-[2rem] font-[Manrope] font-[800] text-center leading-[30px] text-[#6D894E]">
                Innovative Solutions
              </h1>
              <p className="text-[18px] md:text-[20px] px-[2rem] font-[400] font-[Manrope] pt-[2rem] text-center leading-[30px] text-[#2D2D53]">
                We employ cutting-edge technologies and practices to develop
                clean energy solutions that are both effective and sustainable.
              </p>
            </div>
          </div>
          <div className="glide__slide">
            <div className="border-[1px] border-[white] h-fit md:h-[33.188rem] flex flex-col justify-center items-center w-fit drop-shadow-lg rounded-[16px]">
              <img src={approach} alt="approach-image" />
              <h1 className="text-[26px] pt-[2rem] font-[Manrope] font-[800] text-center leading-[30px] text-[#6D894E]">
                Holistic Approach
              </h1>
              <p className="text-[18px] md:text-[20px] font-[400] font-[Manrope] pt-[2rem] text-center leading-[30px] text-[#2D2D53]">
                Our initiatives not only address energy needs but also focus on
                food security, providing comprehensive solutions that uplift
                entire communities.
              </p>
            </div>
          </div>

          <div className="glide__slide">
            <div className="border-[1px] border-[white] h-fit md:h-[33.188rem] flex flex-col justify-center items-center w-fit drop-shadow-lg rounded-[16px]">
              <img src={community} alt="community-image" />
              <h1 className="text-[26px] pt-[2rem] font-[Manrope] font-[800] text-center leading-[30px] text-[#6D894E]">
                Community-Centric
              </h1>
              <p className="text-[18px] md:text-[20px] px-[2rem] font-[400] font-[Manrope] pt-[2rem] text-center leading-[30px] text-[#2D2D53]">
                We work closely with local communities to understand their
                unique challenges and tailor our solutions to meet their
                specific needs.
              </p>
            </div>
          </div>
          <div className="glide__slide">
            <div className="border-[1px] border-[white] h-fit md:h-[33.188rem] flex flex-col justify-center items-center w-fit drop-shadow-lg rounded-[16px]">
              <img src={stewardship} alt="stewardship-image" />
              <h1 className="text-[26px] pt-[2rem] font-[Manrope] font-[800] text-center leading-[30px] text-[#6D894E]">
                Environmental Stewardship
              </h1>
              <p className="text-[18px] md:text-[20px] px-[2rem] font-[400] font-[Manrope] pt-[2rem] text-center leading-[30px] text-[#2D2D53]">
                Our projects are designed to minimize environmental impact,
                promoting a healthier planet for future generations.
              </p>
            </div>
          </div>

          <div className="glide__slide">
            <div className="border-[1px] border-[white] h-fit md:h-[33.188rem] flex flex-col justify-center items-center w-fit drop-shadow-lg rounded-[16px]">
              <img src={economy} alt="economy-image" />
              <h1 className="text-[26px] pt-[2rem] font-[Manrope] font-[800] text-center leading-[30px] text-[#6D894E]">
                Economic Empowerment
              </h1>
              <p className="text-[18px] md:text-[20px] px-[2rem] font-[400] font-[Manrope] pt-[2rem] text-center leading-[30px] text-[#2D2D53]">
                By providing reliable energy and food solutions, we help boost
                local economies, creating jobs and improving living standards.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="glide__arrows" data-glide-el="controls">
        <button className="glide__arrow glide__arrow--left " data-glide-dir="<">
          <svg
            width="12"
            height="21"
            viewBox="0 0 12 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M9.55077 0.417626L0.420222 9.49176C-0.140074 10.0486 -0.140074 10.9514 0.420222 11.5082L9.55077 20.5824C10.1111 21.1392 11.0195 21.1392 11.5798 20.5824C12.1401 20.0255 12.1401 19.1227 11.5798 18.5659L3.46374 10.5L11.5798 2.4341C12.1401 1.87727 12.1401 0.97446 11.5798 0.417626C11.0195 -0.139209 10.1111 -0.139209 9.55077 0.417626Z"
              fill="url(#paint0_linear_130_132)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_130_132"
                x1="12.878"
                y1="15.3677"
                x2="-0.760687"
                y2="14.9931"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#3CAB90" />
                <stop offset="1" stop-color="#0E6752" />
              </linearGradient>
            </defs>
          </svg>
        </button>
        <button
          className="glide__arrow glide__arrow--right right-0"
          data-glide-dir=">"
        >
          <svg
            width="12"
            height="21"
            viewBox="0 0 12 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M2.44923 0.417626L11.5798 9.49176C12.1401 10.0486 12.1401 10.9514 11.5798 11.5082L2.44923 20.5824C1.88894 21.1392 0.980517 21.1392 0.420222 20.5824C-0.140074 20.0255 -0.140074 19.1227 0.420222 18.5659L8.53626 10.5L0.420222 2.4341C-0.140074 1.87727 -0.140074 0.97446 0.420222 0.417626C0.980517 -0.139209 1.88894 -0.139209 2.44923 0.417626Z"
              fill="url(#paint0_linear_248_2)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_248_2"
                x1="-0.877968"
                y1="15.3677"
                x2="12.7607"
                y2="14.9931"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#3CAB90" />
                <stop offset="1" stop-color="#0E6752" />
              </linearGradient>
            </defs>
          </svg>
        </button>
      </div>
    </div>
  );
};

export default Carousel;
