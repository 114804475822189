import React from "react";
import jemima from "../assets/jemima2.webp";
import japhet from "../assets/japhet.webp";
import caleb from "../assets/caleb.webp";
import bieber from "../assets/bieber.webp";
import bernice from "../assets/bernice.webp"
import tolu from "../assets/tolusmall.webp"
import { NavLink } from "react-router-dom";
const Structure = () => {
  return (
    <div className="py-[5rem] ">
      <div className="flex flex-col px-[2rem] xl:px-[9.125rem] my-[6rem] text-center items-center justify-center">
        <div className="border border-b-[#A7A7A7] w-[80vw] xl:w-[60rem] my-[2rem]"></div>

        <p className="font-[Manrope] leading-[30px] md:leading-[54.47px] mb-[3rem] mt-[8rem] text-[#7F9764] text-[24px] md:text-[40px] font-[800]">
          ORGANIZATIONAL STRUCTURE
        </p>
        <div className="w-[100%] max-w-[100%] flex flex-col xl:flex-row items-center justify-center">
          <div className="flex w-[100%] max-w-[100%] xl:max-w-[25%]  flex-col items-center">
            <img className="" src={jemima} alt="jemima" />
            <div>
            <h2 className="font-[Manrope] capitalize pt-[.5rem] leading-[30px] text-[#6D894E] text-[20px] font-[700]">
              Jemimah Appah
            </h2>

            <h2 className="font-[Manrope] leading-[20px] italic text-[black] text-[15px] font-[600]">
              Managing Director
            </h2>
            <NavLink
              to="/jemima"
              className="font-[Manrope] py-[.5rem] leading-[19px] text-[#6D894E] text-[14px] font-[700] "
            >
              {" "}
              Read Profile
            </NavLink>
            </div>
          </div>

          <div className="flex w-[100%] max-w-[100%] xl:max-w-[25%]  flex-col items-center">
            <img className="" src={bernice} alt="bernice" />
            <h2 className="font-[Manrope] capitalize pt-[.5rem] leading-[30px] text-[#6D894E] text-[20px] font-[700]">
            Bernice Effanga
            </h2>

            <h2 className="font-[Manrope] leading-[20px] italic text-[black] text-[15px] font-[600]">
            Sustainable Farm Manager

            </h2>
            <NavLink
              to="/bernice"
              className="font-[Manrope] py-[.5rem] leading-[19px] text-[#6D894E] text-[14px] font-[700] "
            >
              {" "}
              Read Profile
            </NavLink>
          </div>

         
          <div className="flex w-[100%] max-w-[100%] xl:max-w-[25%]  flex-col items-center">
            <img className="" src={caleb} alt="caleb" />
            <h2 className="font-[Manrope] capitalize pt-[.5rem] leading-[30px] text-[#6D894E] text-[20px] font-[700]">
            Caleb Akoh
            </h2>
            <h2 className="font-[Manrope] leading-[20px] italic text-[black] text-[15px] font-[600]">
              Technical Manager
            </h2>
            <NavLink
              to="/caleb"
              className="font-[Manrope] py-[.5rem] leading-[19px] text-[#6D894E] text-[14px] font-[700] "
            >
              {" "}
              Read Profile
            </NavLink>
          </div>
          <div className="flex w-[100%] max-w-[100%] xl:max-w-[25%]  flex-col items-center">
            <img className="" src={bieber} alt="bieber" />

            <h2 className="font-[Manrope] capitalize pt-[.5rem] leading-[30px] text-[#6D894E] text-[20px] font-[700]">
            Barrister Peter Bieba
            </h2>
            <h2 className="font-[Manrope] leading-[20px] italic text-[black] text-[15px] font-[600]">
             Company Secretary
            </h2>
            <NavLink
              to="/bieba"
              className="font-[Manrope] py-[.5rem] leading-[19px] text-[#6D894E] text-[14px] font-[700] "
            >
              {" "}
              Read Profile
            </NavLink>
          </div>

          <div className="flex w-[100%] max-w-[100%] xl:max-w-[25%]  flex-col items-center">
            <img className="" src={japhet} alt="japhet" />

            <h2 className="font-[Manrope] capitalize pt-[.5rem] leading-[30px] text-[#6D894E] text-[20px] font-[700]">
            Japhet Titus
            </h2>
            <h2 className="font-[Manrope] leading-[20px] italic text-[black] text-[15px] font-[600]">
              Technical Support
            </h2>
            <NavLink
              to="/japhet"
              className="font-[Manrope] py-[.5rem] leading-[19px] text-[#6D894E] text-[14px] font-[700] "
            >
              {" "}
              Read Profile
            </NavLink>
          </div>

          <div className="flex w-[100%] max-w-[100%] xl:max-w-[25%]  flex-col items-center">
            <img className="" src={tolu} alt="japhet" />

            <h2 className="font-[Manrope] capitalize pt-[.5rem] leading-[30px] text-[#6D894E] text-[20px] font-[700]">
            Olatunji Tolulope
            </h2>
            <h2 className="font-[Manrope] leading-[20px] italic text-[black] text-[15px] font-[600]">
              Grant and Project Analyst
            </h2>
            <NavLink
              to="/tolu"
              className="font-[Manrope] py-[.5rem] leading-[19px] text-[#6D894E] text-[14px] font-[700] "
            >
              {" "}
              Read Profile
            </NavLink>
          </div>

        </div>
      </div>
    </div>
  );
};

export default Structure;