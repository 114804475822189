import React, { useState, useEffect } from "react";
import aboutimage from "../assets/AboutHero (2).webp";
import heroimageMobile from "../assets/MobileAbout.webp";
const ContactHero = () => {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 768);

  const updateMedia = () => {
    setIsDesktop(window.innerWidth >= 768);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  return (
    <div className="relative">
      <div className="flex flex-col w-[100%] h-[100vh] bg-[#002B3C]/[0.26] bg-blend-multiply">
        <img
          className=" w-[100%] h-[100vh] bg-[#002B3C]/[0.26] mix-blend-multiply "
          src={isDesktop ? aboutimage : heroimageMobile}
          alt="hero-image"
        />
        <div className="absolute mx-[2rem] xl:mx-[9.125rem] bottom-[30rem] font-[Manrope] md:bottom-[5.241rem] lg:bottom-[8.241rem] xl:bottom-[25.241rem]  w-fit   text-[white]">
          <p className="font-[900] text-[30px] md:text-[30px] lg:text-[66px] leading-[40px] sm:leading-[35px] md:leading-[54.99px] lg:leading-[89.88px]">
            We are a call away
          </p>
          <p className="hidden md:flex font-[600] w-fit text-[16px] sm:text-[18px] md:text-[20px] lg:text-[24px] leading-[18px] sm:leading-[20px] md:leading-[25px] lg:leading-[30px]">
            "Reach out to us and join our mission to power a greener, healthier
            planet."
          </p>
        </div>
      </div>
    </div>
  );
};

export default ContactHero;
