import React from 'react';
import Tolu from '../components/Tolu';

const ToluPage = () => {
  return (
    <div>
      <Tolu/>
    </div>
  );
}

export default ToluPage;
