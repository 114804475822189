import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Bookings = () => {
  const [formData, setFormData] = useState({
    formName: "Bookings Form",
    firstName: "",
    lastName: "",
    email: "",
    message: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    fetch("https://formsubmit.co/ajax/info@alvanyenergy.com", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        // Display success toast notification
        toast.success("Bookings Form submitted successfully!");
        // Clear form data after successful submission
        setFormData({
          formName: "Bookings Form",
          firstName: "",
          lastName: "",
          email: "",
          message: "",
        });
      })
      .catch((error) => {
        console.error("Error during form submission:", error);
        // Display error toast notification
        toast.error("Failed to submit form. Please try again later.");
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <div className="bg-transparent my-[6rem] w-[100%] max-w-[100%] px-[2rem] xl:px-[9.125rem] ">
      <h2 className="font-[Manrope] pb-[2rem] leading-[54.47px] text-center text-[#6D894E] text-[40px] font-[700]">
        BOOKINGS
      </h2>
      <form
        onSubmit={handleSubmit}
        className="px-[2rem] py-[3rem] border border-[#6D894E] rounded-[16px] h-[37.9375rem]"
      >
        <input type="hidden" name="formName" value="Quotations Form" />

        <input
          type="text"
          id="first-name"
          name="firstName"
          required=""
          value={formData.firstName}
          onChange={handleInputChange}
          placeholder="First Name"
          className="mb-[2rem] outline-[#6D894E] py-[20px] text-[#6D894E] placeholder:text-[#6D894E] border-[1px] rounded-[8px] pl-[1rem] h-[2.0625rem] w-full border-[#6D894E]"
        />
        <input
          type="text"
          id="last-name"
          name="lastName"
          required=""
          value={formData.lastName}
          onChange={handleInputChange}
          placeholder="Last Name"
          className="mb-[2rem] outline-[#6D894E] py-[20px] text-[#6D894E] placeholder:text-[#6D894E] border-[1px] rounded-[8px] pl-[1rem] h-[2.0625rem] w-full border-[#6D894E]"
        />
        <input
          value={formData.email}
          onChange={handleInputChange}
          type="email"
          id="email"
          name="email"
          placeholder="Email"
          className="mb-[2rem] outline-[#6D894E] py-[20px] text-[#6D894E] placeholder:text-[#6D894E] border-[1px] rounded-[8px] pl-[1rem] h-[2.0625rem] w-full border-[#6D894E]"
        />
        <textarea
          value={formData.message}
          onChange={handleInputChange}
          id="message"
          name="message"
          placeholder="Type your message"
          className="mb-[2rem] outline-[#6D894E] text-[#6D894E] placeholder:text-[#6D894E] border-[1px] rounded-[8px] p-[1rem] h-[8.9375rem] w-full border-[#6D894E]"
        ></textarea>

        <button
          type="submit"
          className="flex font-[600] flex-row items-center justify-center mx-auto font-[Manrope] bg-[#6D894E] border-[#6D894E] px-[2rem] w-fit lg:w-[19.4375rem] border rounded-[30px] h-[3.5rem] text-[white] text-[16px] gap-[0.75rem]"
          disabled={isSubmitting}
        >
          {isSubmitting ? "Submitting..." : "SEND MESSAGE"}
        </button>
      </form>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
};

export default Bookings;
