import React, { useEffect } from "react";
import brands from "../assets/brands.webp";
import transparency from "../assets/transparency.svg";
import integrity from "../assets/integrity.svg";
import sustainability from "../assets/sustainability.svg";
import innovation from "../assets/innovation.svg";
import engagement from "../assets/engagement.svg";
import excellence from "../assets/excellence.svg";
import collaboration from "../assets/collaboration.svg";
import empowerment from "../assets/empowerment.svg";
import useIntersectionObserver from "./useIntersectionObserver";

const Values = () => {
  const observe = useIntersectionObserver((element) => {
    element.classList.add("animate-slideUp");
  }, { threshold: 0.1 });

  useEffect(() => {
    const cards = document.querySelectorAll(".card");
    cards.forEach((card) => observe(card));
  }, [observe]);

  return (
    <div className="relative my-[5rem]">
      <div className="flex flex-col w-[100%] h-fit bg-[#182E00]/[0.63] py-[4rem] bg-blend-multiply justify-center items-center">
        <img
          className="-z-[1] hidden md:flex w-fit md:w-full h-fit md:h-full absolute"
          src={brands}
          alt="brand-image"
        />
        <div className="mx-[2rem] flex flex-col justify-center items-center xl:mx-[9.125rem]">
          <h2 className="font-[Manrope] leading-[54.4px] text-[white] text-[40px] font-[800] pb-[3rem]">
            CORE VALUES
          </h2>
          <div className="flex flex-col gap-[2rem] w-[100%] max-w-[100%] lg:flex-row justify-between items-center">
            <div className="card border py-[2rem] md:py-0 px-[2rem] bg-[white] w-[100%] max-w-[100%] lg:max-w-[33.3%] flex flex-col justify-center items-center h-fit md:h-[480px] rounded-[16px]">
              <img src={transparency} alt="transparency-icon" />
              <p className="font-[Manrope] py-[1rem] font-[700] text-center text-[black] text-[24px] leading-[30px]">
                Transparency
              </p>
              <p className="text-center font-[Manrope] font-[400] text-[black] text-[18px] md:text-[24px] leading-[30px]">
                We are committed to openness and clarity in all our operations, ensuring that our stakeholders are fully informed and engaged in our processes.
              </p>
            </div>
            <div className="card border py-[2rem] md:py-0 px-[2rem] bg-[white] w-[100%] max-w-[100%] lg:max-w-[33.3%] flex flex-col justify-center items-center h-fit md:h-[480px] rounded-[16px]">
              <img src={integrity} alt="integrity-icon" />
              <p className="font-[Manrope] py-[1rem] font-[700] text-center text-[black] text-[24px] leading-[30px]">
                Integrity
              </p>
              <p className="text-center font-[Manrope] font-[400] text-[black] text-[18px] md:text-[24px] leading-[30px]">
                We uphold the highest ethical standards, fostering a culture of honesty, fairness, and accountability in every aspect of our business.
              </p>
            </div>
            <div className="card border py-[2rem] md:py-0 px-[2rem] bg-[white] w-[100%] max-w-[100%] lg:max-w-[33.3%] flex flex-col justify-center items-center h-fit md:h-[480px] rounded-[16px]">
              <img src={sustainability} alt="sustainability-icon" />
              <p className="font-[Manrope] py-[1rem] font-[700] text-center text-[black] text-[24px] leading-[30px]">
                Sustainability
              </p>
              <p className="text-center font-[Manrope] font-[400] text-[black] text-[18px] md:text-[24px] leading-[30px]">
                We are dedicated to sustainable practices that protect the environment and promote long-term ecological balance, ensuring that our actions today do not compromise the ability of future generations to meet their needs.
              </p>
            </div>
          </div>
          <div className="flex flex-col gap-[2rem] w-[100%] max-w-[100%] my-[2rem] lg:flex-row justify-between items-center">
            <div className="card border py-[2rem] md:py-0 px-[2rem] bg-[white] w-[100%] max-w-[100%] lg:max-w-[33.3%] flex flex-col justify-center items-center h-fit md:h-[480px] rounded-[16px]">
              <img src={innovation} alt="innovation-icon" />
              <p className="font-[Manrope] py-[1rem] font-[700] text-center text-[black] text-[24px] leading-[30px]">
                Innovation
              </p>
              <p className="text-center font-[Manrope] font-[400] text-[black] text-[18px] md:text-[24px] leading-[30px]">
                We embrace creativity and innovation, continually seeking new and effective solutions to enhance energy efficiency and food security.
              </p>
            </div>
            <div className="card border py-[2rem] md:py-0 px-[2rem] bg-[white] w-[100%] max-w-[100%] lg:max-w-[33.3%] flex flex-col justify-center items-center h-fit md:h-[480px] rounded-[16px]">
              <img src={engagement} alt="engagement-icon" />
              <p className="font-[Manrope] py-[1rem] font-[700] text-center text-[black] text-[24px] leading-[30px]">
                Community Engagement
              </p>
              <p className="text-center font-[Manrope] font-[400] text-[black] text-[18px] md:text-[24px] leading-[30px]">
                We prioritize the needs of the communities we serve, working collaboratively to empower and uplift those in underserved regions.
              </p>
            </div>
            <div className="card border py-[2rem] md:py-0 px-[2rem] bg-[white] w-[100%] max-w-[100%] lg:max-w-[33.3%] flex flex-col justify-center items-center h-fit md:h-[480px] rounded-[16px]">
              <img src={excellence} alt="excellence-icon" />
              <p className="font-[Manrope] py-[1rem] font-[700] text-center text-[black] text-[24px] leading-[30px]">
                Excellence
              </p>
              <p className="text-center font-[Manrope] font-[400] text-[black] text-[18px] md:text-[24px] leading-[30px]">
                We strive for excellence in all that we do, maintaining the highest standards of quality and continuously improving our services and operations.
              </p>
            </div>
          </div>
          <div className="flex flex-col lg:flex-row justify-center gap-[2rem] items-center">
            <div className="card border py-[2rem] md:py-0 px-[2rem] bg-[white] w-[100%] max-w-[100%] lg:max-w-[33.3%] flex flex-col justify-center items-center h-fit md:h-[480px] rounded-[16px]">
              <img src={collaboration} alt="collaboration-icon" />
              <p className="font-[Manrope] py-[1rem] font-[700] text-center text-[black] text-[24px] leading-[30px]">
                Collaboration
              </p>
              <p className="text-center font-[Manrope] font-[400] text-[black] text-[18px] md:text-[24px] leading-[30px]">
                We believe in the power of teamwork and partnership, building strong relationships with stakeholders, partners, and clients to achieve shared goals.
              </p>
            </div>
            <div className="card border py-[2rem] md:py-0 px-[2rem] bg-[white] w-[100%] max-w-[100%] lg:max-w-[33.3%] flex flex-col justify-center items-center h-fit md:h-[480px] rounded-[16px]">
              <img src={empowerment} alt="empowerment-icon" />
              <p className="font-[Manrope] py-[1rem] font-[700] text-center text-[black] text-[24px] leading-[30px]">
                Empowerment
              </p>
              <p className="text-center font-[Manrope] font-[400] text-[black] text-[18px] md:text-[24px] leading-[30px]">
                We are dedicated to empowering individuals and communities by providing them with the resources and knowledge needed to improve their quality of life.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Values;
