import React from "react";
import bernicebig from "../assets/bernicebig.webp";
const Bernice = () => {
  return (
    <div className="px-[2rem] xl:px-[9.125rem] my-[6rem]">
      <div className="bg-[#6D894E] h-[104px] justify-center flex flex-row items-center">
        <h2 className="uppercase text-[white] text-center  p-auto font-[700] text-[24px] md:text-[41px] leading-[35px] md:leading-[55px]">
          Bernice Effanga - sustainable farm manager
          {" "}
        </h2>
      </div>
      <div
        className="flex my-[4rem] gap-[5rem] flex-col md:flex-row w-[100%] max-w-[100%] items-center
       justify-between"
      >
        <img
          src={bernicebig}
          className="w-[100%] max-w-[100%] md:max-w-[50%] "
          alt="bernicebig"
        />
        <div className="w-[100%] flex flex-col justify-between items-center max-w-[100%] md:max-w-[50%]">
          <div className="font-[500] items-start text-[18px] md:text-[20px] leading-[50px] text-black font-[Manrope]">
            <p>
              {" "}
              As the Sustainable Farm Manager, Bernice brings a wealth of expertise in
              agricultural management, with a strong background in crop
              production and sustainable farming practices.
            </p>

            <p className="py-[1rem]">
              She excels in strategic planning, resource optimization, and team
              leadership, ensuring high productivity and profitability. Her
              commitment to improving the human experience through the provision
              of adequate food supply and environmental stewardship drives
              continuous improvement and success on the farm.
            </p>
          </div>
          <div className="border items-end h-[6px]  bg-[#6D894E] border-b-[#6D894E] w-[100%] my-[2rem]"></div>
        </div>
      </div>
    </div>
  );
};

export default Bernice;
