import React from "react";

const Mission = () => {
  return (
    <div>
      <div className="flex px-[2rem] mb-[4rem] xl:px-[9.125rem] gap-[3rem] flex-col lg:flex-row items-center justify-between ">
        <div>
          <p className="flex font-[600] flex-row items-center justify-center md:justify-normal font-[Manrope] bg-[#6D894E] border-[#6D894E] px-[2rem] w-fit border rounded-[30px] h-[41px] text-[white]  text-[16px] gap-[0.75rem]">
            MISSION STATEMENT
          </p>
          <p className="text-left mt-[1rem] font-[Manrope] w-fit  font-[400] text-[black] text-[18px] md:text-[24px] leading-[32.68px]">
            To revolutionize energy and food security in developing countries by
            harnessing sustainable and renewable resources, creating a brighter
            and more equitable future for all.
          </p>
        </div>
        <div className="border hidden lg:flex border-l-[#A7A7A7] h-[80%] md:h-[10rem] my-[2rem]"></div>

        <div>
          <p className="flex font-[600] flex-row items-center font-[Manrope] bg-[#6D894E] border-[#6D894E] w-fit px-[2rem] border justify-center rounded-[30px] h-[41px] text-[white]  text-[16px] gap-[0.75rem]">
            VISION
          </p>
          <p className="text-left mt-[1rem] font-[Manrope] w-fit font-[400] text-[black] text-[18px] md:text-[24px] leading-[32.68px]">
            To be the foremost leader in Africa for sustainable energy and food
            solutions, pioneering innovative practices that ensure environmental
            and economic prosperity.
          </p>
        </div>
      </div>
      <div className="truncate bg-[#D9D9D9] marquee whitespace-nowrap flex">
      <span className=" pl-[100%] flex font-[Manrope] leading-[30px] tracking-widest text-[black] h-[95px] items-center animate-marquee justify-center flex-row text-[24px] text-center font-[600]">
        EMPOWERING SUSTAINABILITY, ENERGIZING TOMORROW
      </span>
      </div>
    </div>
  );
};

export default Mission;
