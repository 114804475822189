import React from "react";
import bellabig from "../assets/bellabig.webp";
const Bella = () => {
  return (
    <div className="px-[2rem] xl:px-[9.125rem] my-[6rem]">
      <div className="bg-[#6D894E] h-[104px] justify-center flex flex-row items-center">
        <h2 className="uppercase text-[white] text-center  p-auto font-[700] text-[24px] md:text-[41px] leading-[35px] md:leading-[55px]">
          Bella Eke - HEAD FINANCE
        </h2>
      </div>
      <div
        className="flex my-[4rem] gap-[5rem] flex-col md:flex-row w-[100%] max-w-[100%] items-center
       justify-between"
      >
        <img
          src={bellabig}
          className="w-[100%] max-w-[100%] md:max-w-[50%] "
          alt="bellabig"
        />
        <div className="w-[100%] flex flex-col justify-between items-center max-w-[100%] md:max-w-[50%]">
          <div className="font-[500] items-start text-[18px] md:text-[20px] leading-[50px] text-black font-[Manrope]">
            <p>
              {" "}
              Bella Eke is a seasoned finance professional with extensive
              experience in finance and banking, technology and data science,
              project management, and electronic payments. With dual Master's
              degrees in Data Science and Information Systems Management along
              with a Bachelor's degree in Computer Science (Summa Cum Laude).{" "}
            </p>

            <p className="py-[1rem]">
              Bella is passionate about leveraging AI to revolutionize financial
              processes. She excels in strategic planning, business process
              transformations, and enhancing decision-making through data-driven
              insights.
            </p>

          
          </div>
          <div className="border items-end h-[6px]  bg-[#6D894E] border-b-[#6D894E] w-[100%] my-[2rem]"></div>
        </div>
      </div>
    </div>
  );
};

export default Bella;
