import React from 'react';
import Quotations from '../components/Quotations';
import QuotationsHero from '../components/QuotationsHero';

const QuotationsPage = () => {
  return (
    <div>
      <QuotationsHero/>
      <Quotations/>
    </div>
  );
}

export default QuotationsPage;
