import React from "react";

const Aboutus = () => {
  return (
    <div className="flex flex-col px-[2rem] xl:px-[9.125rem] my-[6rem] text-center items-center justify-center">
      <h2 className="font-[Manrope]  leading-[54.47px] text-[#7F9764] text-[40px] font-[800]">
        About US
      </h2>
      <p className="font-[Manrope] leading-[30px] text-[#7F9764] text-[24px] font-[700]">
        Why Choose Alvany Energy{" "}
      </p>
      <div className="border border-b-[#A7A7A7] w-[80vw] md:w-[40rem] my-[2rem]"></div>
      <p className="font-[Manrope] leading-[30px] text-[black] text-[18px] md:text-[24px] font-[400]">
        Alvany Energy is dedicated to addressing the critical issues of energy
        and food shortages in Africa and other developing regions. Our approach
        is rooted in leveraging renewable energy sources, particularly solar
        power, to create sustainable, scalable solutions that enhance quality of
        life and promote environmental stewardship.
        <br />
        <br/>
        Our commitment to sustainability is reflected in our innovative use of
        underutilized resources to develop clean energy and sustainable
        agricultural practices. We believe in harnessing the abundant solar
        energy available to us to create viable, long-term solutions that
        benefit both people and the planet.
      </p>
    </div>
  );
};

export default Aboutus;
