import React from "react";
import ServiceHero from "../components/ServiceHero";
import Whatwedo from "../components/Whatwedo";
import SEO from "../components/SEO";

const Services = () => {
  return (
    <div>
      <SEO
        title="Alvany Energy – Driving Sustainable Innovation for a Brighter Future"
        description=" Innovative solutions for clean energy and sustainable agricultural
            practices"
        image="/public/logo.svg"
        keywords="energy, sustainability, clean energy, green energy, renewable energy"
        url="https://www.alvanyenergy.org/services"
      />{" "}
      <ServiceHero />
      <Whatwedo />
    </div>
  );
};

export default Services;
