import React from 'react';
import Bookings from '../components/Bookings';
import BookingsHero from '../components/BookingsHero';

const BookingsPage = () => {
  return (
    <div>
        <BookingsHero/>
      <Bookings/>
    </div>
  );
}

export default BookingsPage;
