import React from "react";

const WhyChooseUs = () => {
  return (
    <div className="flex flex-col px-[2rem] xl:px-[9.125rem] my-[6rem] text-center items-center justify-center">
      <p className="font-[Manrope] leading-[54.47px] text-[#7F9764] text-[40px] font-[800]">
        WHY CHOOSE US{" "}
      </p>
      <p className="font-[Manrope] leading-[30px] text-[black] text-[18px] md:text-[24px] font-[400]">
        At Alvany Energy, we are passionate about creating a sustainable future.
        Our projects are driven by a deep commitment to improving lives and
        fostering environmental health. By choosing Alvany Energy, you are
        supporting a mission that goes beyond business – you are contributing to
        a movement towards a more sustainable and equitable world.
      </p>
    </div>
  );
};

export default WhyChooseUs;
