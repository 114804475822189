import address from "../assets/address.png";
import call from "../assets/call.png";
import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import mail from "../assets/mail.png";

const ContactComponent = () => {
  const [formData, setFormData] = useState({
    formName: "Contact Form", // Add form name here
    firstName: "",
    lastName: "",
    email: "",
    message: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    fetch("https://formsubmit.co/ajax/info@alvanyenergy.com", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        // Display success toast notification
        toast.success("Contact Form submitted successfully!");
        // Clear form data after successful submission
        setFormData({
          formName: "Contact Form", // Reset form name
          firstName: "",
          lastName: "",
          email: "",
          message: "",
        });
      })
      .catch((error) => {
        console.error("Error during form submission:", error);
        // Display error toast notification
        toast.error("Failed to submit form. Please try again later.");
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <div className="bg-[#6D894E] px-[2rem] xl:px-[9.125rem] py-[4rem] flex flex-col md:flex-row justify-between items-center w-[100%] max-w-[100%] h-fit md:h-[48.8125rem]">
      <div className="w-[100%] max-w-[100%] mb-[3rem] md:mb-0 md:max-w-[50%]">
        <h2 className="font-[Manrope] leading-[54.47px] text-[white] text-[40px] font-[800]">
          CONTACT US
        </h2>
        <p className="font-[Manrope] leading-[30px] text-[white] text-[18px] font-[400]">
          Feel free to reach out to us. Our team of experts <br /> will respond
          to your message promptly
        </p>
        <div className="flex flex-row py-[1rem] items-center gap-10 ">
          <img src={address} alt="address-logo" />
          <p className="font-[Manrope] leading-[30px] text-[white] text-[18px] font-[400]">
            No 2, 6921 Road, Model City, Gwarimpa, Abuja
          </p>
        </div>
        <div className="flex flex-row my-2 pb-[1rem] items-center gap-6 ">
          <img src={call} alt="call-logo" />
          <p className="font-[Manrope] leading-[30px] text-[white] text-[18px] font-[400]">
            (+234) 707 281 4987
          </p>
        </div>
        <div className="flex flex-row items-center gap-6">
          <img src={mail} alt="mail-logo" />
          <p className="font-[Manrope] leading-[30px] text-[white] text-[18px] font-[400]">
            info@alvanyenergy.com
          </p>
        </div>
      </div>

      <div className="bg-[white] p-[2rem] border border-[white] rounded-[16px] h-[37.9375] w-[100%] max-w-[100%] md:max-w-[50%]">
        <h2 className="font-[Manrope] pb-[2rem] leading-[40.85px] text-[#6D894E] text-[30px] font-[700]">
          Contact form
        </h2>
        <form onSubmit={handleSubmit}>
          <input
            type="hidden"
            name="formName"
            value="Contact Form"
          />
          <input
            type="text"
            id="first-name"
            name="firstName"
            required=""
            value={formData.firstName}
            onChange={handleInputChange}
            placeholder="First Name"
            className="mb-[2rem] outline-[#6D894E] py-[20px] text-[#6D894E] placeholder:text-[#6D894E] border-[1px] rounded-[8px] pl-[1rem] h-[2.0625rem] w-full border-[#6D894E]"
          />
          <input
            type="text"
            id="last-name"
            name="lastName"
            required=""
            value={formData.lastName}
            onChange={handleInputChange}
            placeholder="Last Name"
            className="mb-[2rem] outline-[#6D894E] py-[20px] text-[#6D894E] placeholder:text-[#6D894E] border-[1px] rounded-[8px] pl-[1rem] h-[2.0625rem] w-full border-[#6D894E]"
          />
          <input
            value={formData.email}
            onChange={handleInputChange}
            type="email"
            id="email"
            name="email"
            placeholder="Email"
            className="mb-[2rem] outline-[#6D894E] py-[20px] text-[#6D894E] placeholder:text-[#6D894E] border-[1px] rounded-[8px] pl-[1rem] h-[2.0625rem] w-full border-[#6D894E]"
          />
          <textarea
            value={formData.message}
            onChange={handleInputChange}
            id="message"
            name="message"
            placeholder="Type your message"
            className="mb-[2rem] outline-[#6D894E] text-[#6D894E] placeholder:text-[#6D894E] border-[1px] rounded-[8px] p-[1rem] h-[8.9375rem] w-full border-[#6D894E]"
          ></textarea>

          <button
            type="submit"
            disabled={isSubmitting}
            className="flex font-[600] flex-row items-center justify-center mx-auto font-[Manrope] bg-[#6D894E] border-[#6D894E] px-[2rem] w-fit lg:w-[19.4375rem] border rounded-[30px] h-[3.5rem] text-[white]  text-[16px] gap-[0.75rem]"
          >
          {isSubmitting ? "Submitting..." : "SEND MESSAGE"}
          </button>
        </form>

        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
    </div>
  );
};

export default ContactComponent;
