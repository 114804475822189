import React, { useState } from "react";
import logo from "../assets/logo.svg";
import { NavLink } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="flex font-[Manrope] bg-[white] w-[100%] flex-row items-center px-[2rem] xl:px-[9.125rem] justify-between pt-0 md:py-[1rem]">
      <NavLink to="/">
        <img
          className="w-[7rem] md:w-[10.5rem] lg:w-full h-[4.696rem] lg:h-full"
          src={logo}
          alt="planet-savers logo"
        />
      </NavLink>

      <div className="md:hidden">
        <button onClick={toggleMenu} className="text-black text-2xl">
          <FaBars />
        </button>
      </div>

      <div
        className={`${
          isMenuOpen ? "flex ": "hidden"
        } md:flex text-[black] flex-col md:flex-row gap-[2rem]  lg:gap-[5rem] items-center  justify-between absolute md:relative top-0 left-0 md:left-auto w-full md:w-auto bg-[#000000] md:bg-transparent p-[2rem] md:p-0 h-[100vh] md:h-0  z-[1000]`}
      >
        <div className="flex flex-col font-[600] md:flex-row font-[Manrope] gap-[6rem] md:gap-[2rem] xl:gap-[6rem] justify-between items-center w-full md:w-auto">
          <div className="md:hidden flex justify-end w-full">
            <button onClick={toggleMenu} className="text-white text-2xl">
              <FaTimes />
            </button>
          </div>
          <NavLink
            to="/"
            className={({ isActive }) =>
              isActive ? "text-[#6D894E] " : "text-white md:text-black"
            }
            onClick={toggleMenu}
          >
            Home
          </NavLink>
        
          <NavLink
            to="/about-us"
            className={({ isActive }) =>
                isActive ? "text-[#6D894E] " : "text-white md:text-black"
        }
            onClick={toggleMenu}
          >
            About
          </NavLink>
          <NavLink
            to="/services"
            className={({ isActive }) =>
                isActive ? "text-[#6D894E] " : "text-white md:text-black"
        }
            onClick={toggleMenu}
          >
            Services
          </NavLink>
          <NavLink
            to="/contact"
            className={({ isActive }) =>
                isActive ? "text-[#6D894E] " : "text-white md:text-black"
        }
            onClick={toggleMenu}
          >
            Contact us
          </NavLink>
          <NavLink
            to="/bookings"
            className={({ isActive }) =>
                isActive ? "text-[#6D894E] " : "text-white md:text-black"
        }
            onClick={toggleMenu}
          >
            Bookings
          </NavLink>
          
          <NavLink to="/quotations" className="flex md:hidden font-[600] flex-row items-center font-[Manrope] bg-[#6D894E] border-[#6D894E] w-[8.25rem] border justify-center rounded-[30px] h-[41px] text-[white]  text-[16px] gap-[0.75rem]">
          <p>GET A QUOTE</p>
        </NavLink>
        </div>
        </div>

        <NavLink to="/quotations" className="hidden md:flex font-[600] flex-row items-center font-[Manrope] bg-[#6D894E] border-[#6D894E] w-[8.25rem] border justify-center rounded-[30px] h-[41px] text-[white]  text-[16px] gap-[0.75rem]">
          <p>GET A QUOTE</p>
        </NavLink>
      </div>
  );
};

export default Navbar;
