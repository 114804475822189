import React from "react";
import japhetbig from "../assets/japhetbig.webp";
const Japhet = () => {
  return (
    <div className="px-[2rem] xl:px-[9.125rem] my-[6rem]">
      <div className="bg-[#6D894E] h-[104px] justify-center flex flex-row items-center">
        <h2 className="uppercase text-[white] text-center  p-auto font-[700] text-[24px] md:text-[41px] leading-[35px] md:leading-[55px]">
          Japhet Titus - TECHNICAL SUPPORT{" "}
        </h2>
      </div>
      <div
        className="flex my-[4rem] gap-[5rem] flex-col md:flex-row w-[100%] max-w-[100%] items-center
       justify-between"
      >
        <img
          src={japhetbig}
          className="w-[100%] max-w-[100%] md:max-w-[50%] "
          alt="japhetbig"
        />
        <div className="w-[100%] flex flex-col justify-between items-center max-w-[100%] md:max-w-[50%]">
          <div className="font-[500] items-start text-[18px] md:text-[20px] leading-[50px] text-black font-[Manrope]">
            <p>
              {" "}
              As the Technical Support Manager, Japhet Titus brings a wealth of
              expertise in IT management, with a strong background in systems
              administration and customer service excellence.
            </p>

            <p className="py-[1rem]">
              He excels in troubleshooting complex technical issues, optimizing
              IT resources, and leading support teams, ensuring high system
              reliability and user satisfaction.{" "}
            </p>

            <p className="">
              His commitment to enhancing user experience through prompt issue
              resolution and proactive maintenance drives continuous improvement
              and success in our technical operations.
            </p>
          </div>
          <div className="border items-end h-[6px]  bg-[#6D894E] border-b-[#6D894E] w-[100%] my-[2rem]"></div>
        </div>
      </div>
    </div>
  );
};

export default Japhet;
