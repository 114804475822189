import React from "react";
import jemimabig from "../assets/jemimabig.webp";
const Jemima = () => {
  return (
    <div className="px-[2rem] xl:px-[9.125rem] my-[6rem]">
      <div className="bg-[#6D894E] h-[104px] justify-center flex flex-row items-center">
        <h2 className="uppercase text-[white] text-center  p-auto font-[700] text-[24px] md:text-[41px] leading-[35px] md:leading-[55px]">
          Jemimah Appah - managing director
        </h2>
      </div>
      <div className="flex my-[4rem] gap-[5rem] flex-col md:flex-row w-[100%] max-w-[100%] items-center
       justify-between">
        <img
          src={jemimabig}
          className="w-[100%] max-w-[100%] md:max-w-[50%] "
          alt="jemima"
        />
        <div className="w-[100%] flex flex-col justify-between items-center max-w-[100%] md:max-w-[50%]">
          <div className="font-[500] items-start text-[18px] md:text-[20px] leading-[50px] text-black font-[Manrope]">
            <p>
              {" "}
              An accomplished Administrative Professional with over 5 years of
              industry experience, specializing in administrative services,
              business account management, sales, and strategic planning.
            </p>

            <p className="py-[1rem]">
              Adept at organizing day-to-day operations and facilitating
              seamless interactions with clients both within and outside the
              organization. Holds a BSc in Economics & Management Science and
              certifications in Human Resources Management and Project
              Management Planning.
            </p>

            <p>
              Known for exceptional organizational skills, strategic insight,
              and a commitment to driving operational excellence, ensuring
              Alvany Energy’s continued growth and success.
            </p>
          </div>
          <div className="border items-end h-[6px]  bg-[#6D894E] border-b-[#6D894E] w-[100%] my-[2rem]"></div>
        </div>
      </div>
    </div>
  );
};

export default Jemima;
