import React from 'react';
import Japhet from '../components/Japhet';

const JaphetPage = () => {
  return (
    <div>
        <Japhet/>
      
    </div>
  );
}

export default JaphetPage;
