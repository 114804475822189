import React from "react";
import AboutHero from "../components/AboutHero";
import WhyChooseUs from "../components/WhyChooseUs";
import Carousel from "../components/Carousel";
import Structure from "../components/Structure";
import SEO from "../components/SEO";
const About = () => {
  return (
    <div>
      <SEO
        title="Alvany Energy – Championing Renewable Energy"
        description="Dedicated to revolutionizing energy and food security in Africa"
        image="/public/logo.svg"
        keywords="energy, sustainability, clean energy, green energy, renewable energy"
        url="https://www.alvanyenergy.org/about-us"
      />{" "}
      
      <AboutHero />
      <WhyChooseUs />
      <Carousel />
      <Structure />
    </div>
  );
};

export default About;
