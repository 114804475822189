import React from "react";
import white from "../assets/alvarywhite.svg";
import address from "../assets/address.png";
import call from "../assets/call.png";
import mail from "../assets/mail.png";
import linkedin from "../assets/linkedin.png";
import twitter from "../assets/twitter.png";
import facebook from "../assets/facebook.png";
import layer from "../assets/Layer.png";
import { NavLink } from "react-router-dom";

const Footer = () => {
  return (
    <div className="bg-[#6D894E] relative  h-fit lg:h-[30rem]  py-[5rem] ">
      <img className="absolute  bottom-0 h-fit lg:h-[30rem]" src={layer} alt="layer" />
      <div className="px-[2rem] relative z-[1000] xl:px-[9.125rem] flex flex-col lg:flex-row justify-between items-start">
        <div className="mb-[2rem] lg:mb-0">
          <img src={white} alt="whitelogo" />
          <p className="text-[white]">&copy;2024 Alvany Energy</p>
        </div>
        <div className="mb-[4rem] lg:mb-0">
          <h2 className="font-[Manrope] leading-[35.41px] text-[white] text-[26px] font-[800]">
            Contact us
          </h2>
          <div className="flex flex-row py-[1rem] items-center gap-10 ">
            <img src={address} alt="addresslogo" />
            <p className="font-[Manrope] leading-[30px] text-[white] text-[18px] font-[400]">
              No 2, 6921 Road, Model City,
              <br /> Gwarimpa, Abuja
            </p>
          </div>
          <div className="flex flex-row my-2 pb-[1rem] items-center gap-6 ">
            <img src={call} alt="calllogo" />
            <p className="font-[Manrope] leading-[30px] text-[white] text-[18px] font-[400]">
              <a href="tel:+2347072814987">(+234) 707 281 4987</a>
            </p>
          </div>
          <div className="flex flex-row items-center gap-6">
            <img src={mail} alt="maillogo" />
            <p className="font-[Manrope] leading-[30px] text-[white] text-[18px] font-[400]">
              info@alvanyenergy.com
            </p>
          </div>
        </div>

        <div className="mb-[4rem] lg:mb-0">
          <h2 className="font-[Manrope]  leading-[35.41px] text-[white] text-[26px] font-[800]">
            Stay updated
          </h2>

          <p className="font-[Manrope] leading-[30px] py-[1rem] text-[white] text-[18px] font-[400]">
            Don’t miss out on our latest offers
            <br /> and promotions.{" "}
          </p>

          <div className="flex flex-row items-center w-full justify-between">
            <a href="https://www.linkedin.com/in/alvany-energy-40418331b/">
              <img src={linkedin} alt="linkedinlogo" />
            </a>
            <a href="https://x.com/AlvanyEnergy">
              <img src={twitter} alt="twitterlogo" />
            </a>
            <a href="https://web.facebook.com/profile.php?id=61563264208775">
              <img src={facebook} alt="facebooklogo" />
            </a>
          </div>
        </div>
        <div className="mb-[2rem] lg:mb-0 flex flex-col">
          <h2 className="font-[Manrope] pb-[1rem] leading-[35.41px] text-[white] text-[26px] font-[800]">
            Quick Links
          </h2>
          <NavLink to="/" className="cursor-pointer ">
            <p className="font-[Manrope] pb-[1rem] leading-[30px] text-[white] text-[18px] font-[400]">
              Home
            </p>
          </NavLink>
          <NavLink to="/about-us" className="cursor-pointer ">
            <p className="font-[Manrope] pb-[1rem] leading-[30px] text-[white] text-[18px] font-[400]">
              About
            </p>
          </NavLink>{" "}
          <NavLink to="/services" className="cursor-pointer ">
            <p className="font-[Manrope] pb-[1rem] leading-[30px] text-[white] text-[18px] font-[400]">
              Services
            </p>
          </NavLink>{" "}
          <NavLink to="/contact" className="cursor-pointer ">
            <p className="font-[Manrope] pb-[1rem] leading-[30px] text-[white] text-[18px] font-[400]">
              Contact Us
            </p>
          </NavLink>{" "}
          <NavLink to="/bookings" className="cursor-pointer ">
            <p className="font-[Manrope] pb-[1rem] leading-[30px] text-[white] text-[18px] font-[400]">
              Bookings
            </p>
          </NavLink>{" "}
          <NavLink to="/contact" className="cursor-pointer ">
            <p className="font-[Manrope] pb-[1rem] leading-[30px] text-[white] text-[18px] font-[400]">
              Get a quote
            </p>
          </NavLink>{" "}
        </div>
      </div>
    </div>
  );
};

export default Footer;
