import React from 'react';
import Bieber from '../components/Bieber';

const BieberPage = () => {
  return (
    <div>
      <Bieber/>
    </div>
  );
}

export default BieberPage;
