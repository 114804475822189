import React from 'react';
import Caleb from '../components/Caleb';

const CalebPage = () => {
  return (
    <div>
        <Caleb/>
      
    </div>
  );
}

export default CalebPage;
