import React from 'react';
import Jemima from '../components/Jemima';

const JemimaPage = () => {
  return (
    <div>
      <Jemima/>
    </div>
  );
}

export default JemimaPage;
