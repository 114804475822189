import React from "react";
import HomeHero from "../components/HomeHero";
import Aboutus from "../components/Aboutus";
import Values from "../components/Values";
import Mission from "../components/Mission";
import SEO from "../components/SEO";
const Home = () => {
  return (
    <div>
      <SEO
        title="Alvany Energy – Empowering Sustainability, Energizing Tomorrow"
        description="We are committed to creating a future where clean energy and sustainable
        food production are accessible to all. Join us in our mission to power a
        greener, healthier planet."
        keywords="energy, sustainability, clean energy, green energy, renewable energy"
        image="/public/logo.svg"
        url="https://www.alvanyenergy.org"
      />{" "}
      
      <HomeHero />
      <Aboutus />
      <Values />
      <Mission />
    </div>
  );
};

export default Home;
